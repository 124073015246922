import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import StyledButton from '../../components/Button';
import ContactFormCom from '../../components/ContactFormCom';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';
import SingleOffersGallery from '../../components/SingleOffersGallery';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledH3 = styled.h3`
  color: #59388f;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

export default function BozeNarodzenie({ data }) {
  return (
    <>
      <SEO title="Boże Narodzenie - wianki świąteczne do powieszenia" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            <b>Boże Narodzenie</b> to czas w którym chcemy aby wokół Nas
            zapanowała atmosfera ciepła i harmonii. Odpowiednio dobrane ozdoby
            mają sprawić, iż wnętrza wyglądają jak z bajki.
          </StyledParagraph>
          <StyledParagraph>
            Nasza pracownia tworzy
            <b>
              {' '}
              ozdoby świąteczne w postaci wianków do powieszenia na drzwi,
              ściany lub do zawieszenia pod sufitem
            </b>
            . Nadają się do przyozdobienia domu, biura, restauracji, hotelu a
            nawet witryn sklepowych.
          </StyledParagraph>
          <StyledParagraph>
            Bazujemy na <b>żywej zieleni z naturalnymi dodatkami</b> (szyszki,
            kora itp.); suszonymi owocami jak i również bombeczkami.
            Przygotowywane przez Nas wianki charakteryzują się różnymi stylami:
            od rustykalnego poprzez sielski jak i glamour. Wszystkie tworzone z
            najlepszej jakości dodatków oraz podążające za najnowszymi trendami.
          </StyledParagraph>
          <StyledParagraph>
            Zapraszam do współpracy a z pewnością sprawię, że świąteczna
            atmosfera będzie niepowtarzalna.
          </StyledParagraph>
          <StyledH3>OFERUJEMY</StyledH3>
          <StyledList>
            {data.singleOffer.listOfOffers.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </StyledList>
          <ButtonWrapper>
            <StyledButton as={Link} to="/kontakt">
              Skontaktuj się z nami
            </StyledButton>
          </ButtonWrapper>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <OffersWrapper>
        <StyledTitleOffers>nasze realizacje</StyledTitleOffers>
        <SingleOffersGallery offerTitle="Boże Narodzenie" />
        <ButtonWrapper>
          <StyledButton
            className="white"
            as={Link}
            to="/galeria/boze-narodzenie"
          >
            Zobacz więcej zdjęć
          </StyledButton>
        </ButtonWrapper>
      </OffersWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query BozeNarodzenieQuery {
    singleOffer: sanityOffer(slug: { current: { eq: "boze-narodzenie" } }) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
